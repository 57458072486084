import React, { FunctionComponent } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { BtnPrimary } from '../../elements/btn'
import { ADD_TO_CART_MUTATION } from '../../../graphql/mutations'

// import { Cart, OrderItem } from '../../types'

// interface CartQueryInterface {
//   data: Cart
// }
interface State {
  productSku: string
}
const AddBtn: FunctionComponent<State> = ({ productSku }) => {
  // const { data } = useQuery<CartQueryInterface>(CART_QUERY)
  const [addItemToCart] = useMutation(ADD_TO_CART_MUTATION, { variables: { sku: productSku } })
  // muesli_001
  return <BtnPrimary onClick={addItemToCart}>Ajouter</BtnPrimary>
}

export default AddBtn
