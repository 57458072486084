import React, { FunctionComponent } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { v4 as uuid } from 'uuid'
import { ProductType } from '../../data/types'
import ProductItem from '../Product'
import { StyledCatalogue } from './style'
import H2 from '../elements/headings'
import Section from '../elements/section'
import { CART_QUERY } from '../../graphql/queries'
import { OrderItem } from '../../types/index'

interface CatalogueState {
  products: Array<Product>
}

const renderProductItem = (orderItems: Array<OrderItem>, product: ProductType) => {
  const orderItem = orderItems.find((item) => item.product.sku === product.sku)
  const quantity = orderItem === undefined ? 0 : orderItem.quantity
  return <ProductItem key={uuid()} product={product} quantity={quantity} />
}

const productByCategory = (products, category_id) => {
  return products.filter((product) => product.category_id === category_id)
}

const Catalogue: FunctionComponent<CatalogueState> = ({ products, categories }) => {
  const { data } = useQuery(CART_QUERY)
  return (
    <StyledCatalogue>
      <Section>
        {categories.map((category) => (
          <div key={uuid()}>
            <div>
              <H2>{category.title}</H2>
            </div>
            <div className="grid">
              {productByCategory(products, category.id).map((product) =>
                renderProductItem(data.cart.order_items, product),
              )}
            </div>
          </div>
        ))}
      </Section>
    </StyledCatalogue>
  )
}

export default Catalogue
