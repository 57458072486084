import { graphql, PageProps } from 'gatsby'
import React, { FunctionComponent } from 'react'

import Layout from '../components/layout'

import Container from '../components/elements/container'
import Catalogue from '../components/Catalogue'
// import AVAILABLE_PRODUCTS from '../data/available_products'
import type { Product, Categories } from '../types/index'

// Please note that you can use https://github.com/dotansimha/graphql-code-generator
// to generate all types from graphQL schema
interface IndexPageProps extends PageProps {
  data: {
    allProduct: {
      nodes: Array<Product>
    }
    allCategories: {
      nodes: Array<Categories>
    }
  }
}

export const productsQuery = graphql`
  query ProductsQuery {
    allProduct {
      nodes {
        id
        price
        sku
        thumbnail_url
        title
        vat
        description
        category_id
      }
    }
    allCategories {
      nodes {
        id
        title
        description
      }
    }
  }
`

const IndexPage: FunctionComponent<IndexPageProps> = ({ data }) => (
  <Layout>
    <Container>
      <Catalogue products={data.allProduct.nodes} categories={data.allCategories.nodes} />
    </Container>
  </Layout>
)

export default IndexPage
