import React, { FunctionComponent } from 'react'
import { Product as ProductState } from '../../data/types'
import { toCurrency } from '../../utils/format'
import AddBtn from './AddBtn'
import PlusBtn from './PlusBtn'
import MinusBtn from './MinusBtn'
import { QuantityBtn } from '../elements/btn'

import { ImageWrapper, ProductCard, ProductContent, ProductButtonAndPrice } from './style'

const Product: FunctionComponent<{ product: ProductState; quantity: number }> = ({
  product,
  quantity,
}) => {
  return (
    <ProductCard>
      <ImageWrapper>
        <img src={`${product.thumbnail_url}&w=500&h=400`} />
      </ImageWrapper>
      <ProductContent>
        <div className="product-title">
          <h3>{product.title}</h3>
        </div>
        <div className="product-description">
          <p>{product.description}</p>
        </div>
        <ProductButtonAndPrice>
          <div className="product-price">
            <p>{toCurrency(product.price)}</p>
          </div>
          {quantity > 0 ? (
            <QuantityBtn>
              <PlusBtn productSku={product.sku} />
              <p>{quantity}</p>
              <MinusBtn productSku={product.sku} />
            </QuantityBtn>
          ) : (
            <AddBtn productSku={product.sku} />
          )}
        </ProductButtonAndPrice>
      </ProductContent>
    </ProductCard>
  )
}

export default Product
