/** @jsx jsx */

import styled from '@emotion/styled'
import tw from 'twin.macro'

export const ProductCard = styled.div`
  ${tw`
    flex
    flex-col
    rounded-lg
    shadow-lg
    overflow-hidden
  `}
`

export const ProductContent = styled.div`
  ${tw`
    flex-1
    bg-white
    p-4
    md:p-6
    flex
    flex-col
    justify-between
  `}

  h3 {
    ${tw`
      mt-2
      text-xs
      md:text-base
      leading-7
      font-bold
      md:font-semibold
      text-gray-900
      h-20
    `}
  }

  .product-description {
    ${tw`
      text-xs
      leading-7
      text-gray-500
    `}
    p {
      ${tw`
        h-20
      `}
    }
  }
`

export const ProductDescription = styled.div`
  ${tw`
    flex
    flex-wrap
  `}
  p {
    ${tw`
      w-full
      text-gray-600
      text-xs
      mb-5
      md:mb-6
      leading-5
    `}
  }
`

export const ImageWrapper = styled.div`
  ${tw`flex-shrink-0`}
  img {
    ${tw`
      h-48
      w-full
      object-cover
  `}
  }
`

export const ProductQuantityAndPrice = styled.div`
  ${tw`
    inline-flex
    items-center
    justify-between
    my-5
    md:my-6
  `}
  .product-price {
    ${tw`
      text-gray-900
      text-base
      md:text-lg
    `}
  }
`

export const ProductButtonAndPrice = styled.div`
  ${tw`
    mt-5
    inline-flex
    items-center
    justify-between
  `}
  p {
    ${tw`
      text-gray-900
      text-base
      md:text-lg
    `}
  }
`
