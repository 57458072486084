/** @jsx jsx */

import styled from '@emotion/styled'
import tw from 'twin.macro'

export const StyledCatalogue = styled.div`
  .grid {
    ${tw`
      my-12
      grid
      gap-3
      md:gap-5
      lg:max-w-lg
      lg:mx-auto
      grid-cols-2
      md:grid-cols-3
      lg:grid-cols-5
      lg:max-w-none
    `}
  }
`
